import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Countries } from "../../../widgets/Countries";
import { Form } from "react-bootstrap";
import CheckoutForm from "../../../widgets/CheckoutForm";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import AddressSearch from "../../../widgets/AddressSearch";
import { GlobalContext } from "../../../App";
import Spinner from "../../../widgets/Spinner";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    setValue,
    control, reset
  } = useForm();
  const [quantity, setQuantity] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [sameAddress, setSameAddress] = useState(false);
  const [country, setCountry] = useState("");
  const [isZipCodeDisabled, setIsZipCodeDisabled] = useState(true);
  const locationRef = useRef();
  const locationRef1 = useRef();
  const navigate = useNavigate();
  const { showSpinner, setShowSpinner, assetUrl } = useContext(GlobalContext)
  // quantity funtionality
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
      setFinalPrice(newQuantity * 1); // update final price based on quantity
    }
  };
  const removeToken = () => {
    localStorage.removeItem("Stripetoken");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", removeToken);

    return () => {
      window.removeEventListener("beforeunload", removeToken);
    };
  }, []);

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      setFinalPrice(quantity - 1); // update final price based on quantity
    } else {
      setQuantity(0);
      setFinalPrice(0);
    }
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    setFinalPrice(quantity + 1); // update final price based on quantity
  };
  // clear shipping address if * address 1 * is cleard with location and checkbox selected
  const shipping_address1 = useWatch({ control, name: 'shipping_address1' });
  useEffect(() => {
    if (shipping_address1 === undefined || shipping_address1 === "") {
      setSameAddress(false); // Set sameAddress to false if shipping_address1 is undefined or empty
    } else {
    }
  }, [shipping_address1]);
  //****************************************** */ payment api 
  const token = localStorage.getItem("Stripetoken");
  const handlePayment = async (data) => {
    if (!token) {
      toast.error("Card details are required");
      return;
    }
    setShowSpinner(true)
    try {
      const amount = 1
      const paymentData = {
        ...data,
        sku: "SKU44",
        amount: "1",
        quantity: quantity,
        total_amount: quantity * amount,
        finalPrice: finalPrice,
        token: token ? token : "",
        company: "The Nikki Parker Skincare Protocol",
        item_title: "NP Skincare Regimen"
      };
      const response = await fetch(
        ` ${process.env.REACT_APP_BASE_URL}/skin-bundle/payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer "token"`,
          },
          body: JSON.stringify(paymentData),
        }
      );
      if (response?.status === 200) {
        setShowSpinner(false)
        const data = await response.json();
        toast.success(data?.message);
        navigate("/confirmation", { state: { paymentData } });
        removeToken();
        reset()
      } else if (response?.status >= 400) {
        setShowSpinner(false)
        const data = await response.json();
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
      setShowSpinner(false)
    }
  };
  //*************** */ get keys from location 
  const handleChangeAddress = (data) => {
    setValue("shipping_address1", data.location);
    setValue("city", data.city);
    setValue("state", data.state);
    setValue("zip_code", data.zip_code);
    setValue("country", data.country);
    setValue("street_number", data.street_number);
    if (data.zip_code) {
      setIsZipCodeDisabled(false);
    } else {
      setIsZipCodeDisabled(true);
    }

  };
  const handleChangeAddress1 = (data) => {
    setValue("shipping_address2", data.location);
    // setValue("city", data.city);
    // setValue("state", data.state);
    // setValue("zipcode", data.zipCode);
    // setValue("country", data.country);
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, []);
  // Render the error message for the card field based on conditions
  const renderCardErrorMessage = () => {
    if (token === null && isSubmitted) {
      return (
        <small role="alert" className="text-danger alert-msg top-50 mt-3 position-absolute start-0">
          Card is required
        </small>
      );
    }
    return null;
  };
  console.log(quantity, "quantity");
  return (
    <>
      {showSpinner && <Spinner />}
      <div id="wrapper" className="  order-sec">
        <div className="page-container">
          <div className="px-3">
            {/* Product details and description */}
            <div className="cp_product-wrapper flex flex-col w-full gap-y-4 pt-3">
              <div className="cp_product-content">
                <div className="cp_product-title-wrapper">
                  <div className="cp_product-title-container">
                    <h4 className="Inter_Med cp_product-title fw-bold break-words mb-0">
                      The Nikki Parker Skincare Protocol
                    </h4>
                  </div>
                </div>
                <div className="cp_sold-by-wrapper mt-1  mb-3 Inter_Med">
                  <span className="cp_sold-by-seller fw-medium">
                    Skin Care Protocols
                  </span>
                </div>
              </div>
              <div className="overflow-hidden cp_product-description-wrapper">
                <div className="cp_product-description">
                  <div className="text-center mb-4">
                    <img
                      src={assetUrl + "user.png"}
                      alt="undefined"
                      width="100%"
                    />
                  </div>
                  <p className="mb-4 Inter_Med ">Curated Medical Grade Protocol:</p>
                  <div className="ind-sec title">
                    <span className="Inter_Med fw-bold">
                      <u>Inside Nikki Parker's Starter Protocol</u>
                    </span>
                    <ul className="ind-list list-unstyled mb-0">
                      <li>1 - Cleanser</li>
                      <li>1 - Exfoliating Scrub</li>
                      <li>1 - Toner Pads</li>
                      <li>1 - Daily Antioxidant Moisturizer</li>
                      <li>1 - Anti-Aging Serum</li>
                      <li>1 - Sunscreen</li>
                    </ul>
                  </div>
                  <p className="mt-4">*Includes Added Bonus</p>
                  <hr />
                  <div className="boun-sec title">
                    <span className="Inter_Med fw-bold">
                      <u>Added Bonus:</u>
                    </span>
                    <ul className="mb-0 ps-4 boun-list">
                      <li>Free Skincare Product to Support Protocol</li>
                      <li>3-Month Trial to PRIX: Automated Patient Care App</li>
                      <li>
                        Free Skin Analysis from Licensed Practitioner via PRIX
                      </li>
                      <li>24/7 Automated Product Guidance via PRIX</li>
                    </ul>
                  </div>
                  <hr />
                  <div className="info title">
                    <p className="Inter_Med fw-bold">
                      <u>Shipping Information:</u>
                    </p>
                    <p >
                      **FREE Shipping is INCLUDED for ALL orders shipped to
                      continental contiguous United States addresses. Orders
                      shipping to Alaska or Hawaii will incur additional shipping
                      cost and must ship via UPS 2nd Day Air.
                    </p>
                    <p className="my-4">
                      Please allow 1-2 business days for order processing and 1-5
                      business days for shipping. Orders are processed M-F 9am-2pm
                      Central Standard Time. Any orders placed after this time,
                      during the weekend, or on a holiday, will be processed
                      within 1-2 business days from the order date.
                    </p>
                  </div>
                  <p className="Inter_Med fw-bold">
                    <u>U.S. Territories and International Shipping</u>
                  </p>
                  <p>
                    At this time, we do not offer shipping to U.S. territories or
                    to International addresses.
                  </p>
                  <hr />
                  <p className="Inter_Med fw-bold">
                    <u>Tracking Your Order</u>
                  </p>
                  <p>
                    Once our team packs and ships your order, you will receive a
                    confirmation email with a shipment tracking number. All our
                    orders are shipped via UPS Ground or USPS. If you have
                    questions about shipping or tracking your order, please
                    contact us at email:{" "}
                    <a className="Inter_Med text-black" href="mailto:support@skincareprotocols.com">
                      support@skincareprotocols.com
                    </a>{" "}
                    8:30 a.m. to 7:30 p.m. Central Standard Time.
                  </p>
                  <p className="Inter_Med fw-bold mt-4">
                    <u>Order Processing</u>
                  </p>
                  <ul className=" ps-4">
                    <li>
                      Shipping is contingent on stock availability and payment
                      authorization.
                    </li>
                    <li>
                      Sales tax are included in the price of order, if applicable.
                    </li>
                    <li>
                      Orders are shipped on business days only and are not
                      processed on weekends nor US federal holidays.
                    </li>
                    <li>
                      Renew Beauty and Skincareprotocols.com are not responsible
                      for any lost or stolen packages, or any packages that have
                      delayed shipping due to weather or shipping carrier related
                      issues.
                    </li>
                  </ul>
                  <hr />
                  <p className="Inter_Med fw-bold ">
                    <strong>
                      <u>Product Return and Exchanges</u>
                    </strong>
                  </p>
                  <ul className=" ps-4">
                    <li>
                      Because of the specialized nature of medical-grade skincare
                      products, returns cannot be accepted.
                    </li>
                    <li>
                      Due to the inability to recover transaction fees when an
                      order is cancelled, all sales are final.
                    </li>
                    <li>
                      We apologize if you have received items that may have been
                      damaged during transit. Please contact us at email:{" "}
                      <a className="Inter_Med text-black" href="mailto:support@skincareprotocols.com">
                        support@skincareprotocols.com
                      </a>{" "}
                      within 7 days of receipt. Please include a photo of the
                      damaged product(s) along with lot and expiration date.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Product quantity and price */}
            <div className="cp_variant-option p-3 border rounded-2">
              <div className="w-full">
                <div className="d-flex flex-nowrap w-full gap-4 text-start">
                  <div className="cp_variant-text-wrapper">
                    <div className="Inter_Med cp_variant-name overflow-hidden fw-medium">
                      The Nikki Parker Starter Protocol
                    </div>
                    <div className="cp_variant-description">
                      Get started with a few of Nicole's favorites to get your
                      skin prepped for the complete protocol
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="cp_variant-price-wrapper text-right">
                      <div className="text-end text-decoration-line-through text-black-50">
                        $800
                      </div>
                      <div className="cp_variant-price text-end  fw-bold ">$1</div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 w-full">
                  <div className="cp_variant-quantity-wrapper d-flex items-stretch">
                    <button
                      id="decrement"
                      className="bg-transparent border-0"
                      onClick={handleDecrement}
                    >
                      <img src={assetUrl + "neg.svg"} alt="" width={"20px"} />
                    </button>
                    <input
                      className="form-control w-25 text-center"
                      type="number"
                      id="quantity"
                      value={quantity}
                      onChange={handleQuantityChange}
                    />
                    <button
                      id="increment"
                      className="bg-transparent border-0"
                      onClick={handleIncrement}
                    >
                      <img src={assetUrl + "plus.svg"} alt="" width={"20px"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="px-3 pb-3">
            <Form onSubmit={handleSubmit(handlePayment)} autoComplete="new-text">
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Full Name*</Form.Label>
                <Form.Control autoComplete="new-text"
                  className={errors.name ? "border-danger" : ""}
                  type="text"
                  placeholder="Full Name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <small role="alert" className="text-danger alert-msg">
                    Name is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address*</Form.Label>
                <Form.Control autoComplete="new-text"
                  className={errors.email ? "border-danger" : ""}
                  type="email"
                  placeholder="Email address"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <small role="alert" className="text-danger alert-msg">
                    Email is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Phone*</Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        inputClass="form-control w-100"
                        country={"us"} // set the defaultCountry to the selected country code
                        value={field.value}
                        autoComplete="new-text"
                        onChange={field.onChange}
                        containerClass={errors.phone ? "border-danger req-box" : ""}
                      />
                    </>
                  )}
                />
                {errors.phone && (
                  <small role="alert" className="text-danger alert-msg">
                    Phone is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAddress1">
                <Form.Label>Shipping Address*</Form.Label>
                <Controller
                  name="shipping_address1"
                  control={control}
                  autoComplete="new-text"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <AddressSearch
                        className={` ${(Object.values(errors)?.length > 0 && !locationRef?.current?.value) ? "require border-danger" : ""}`}
                        handleChange={handleChangeAddress}
                        locationRef={locationRef}
                        reset={reset} autoComplete="street-address"
                      />

                    </>
                  )}
                />
                {((Object.values(errors)?.length > 0 && !locationRef?.current?.value)) && (
                  <small role="alert" className="text-danger alert-msg">
                    Address is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAddress2">
                <Form.Label className="d-flex justify-content-between">Billing address
                  <div className="text-end ">
                    <Form.Check
                      type="checkbox"
                      className="add-checkbox ps-0 d-flex align-items-center flex-row-reverse "
                      label="Same as Shipping Address"
                      checked={sameAddress}
                      id="add02"
                      onChange={(e) => {
                        setSameAddress(e.target.checked);
                        if (e.target.checked) {
                          // If checkbox is checked, set shipping_address2 to be the same as shipping_address1
                          setValue("shipping_address2", watch("shipping_address1"));
                        } else {
                          // If checkbox is unchecked, reset shipping_address2
                          setValue("shipping_address2", "");
                        }
                      }}
                      title={watch('shipping_address1') === undefined ? "Please select Shipping Address 1" : ""}
                    // disabled={watch('shipping_address1') === "" || watch('shipping_address1') === undefined}
                    />
                  </div></Form.Label>
                {sameAddress ? (
                  <Form.Control
                    type="text"
                    className="static-add"
                    value={watch('shipping_address1')}
                    disabled autoComplete="new-text"
                  />
                ) : (
                  <>
                    <Controller
                      name="shipping_address2"
                      control={control}
                      autoComplete="new-text"
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <AddressSearch
                            className={` ${(Object.values(errors)?.length > 0 && !locationRef?.current?.value) ? "require" : ""}`}
                            handleChange={handleChangeAddress1}
                            locationRef={locationRef1}
                            reset={reset}
                          />

                        </>
                      )}
                    />
                  </>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCountry">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  disabled={watch('shipping_address1')}
                  aria-label="Default select example"
                  className={(errors.country && (Object.values(errors)?.length > 0 && !locationRef?.current?.value)) ? "border-danger" : ""}
                  {...register("country",)}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select Country</option>
                  {Countries.map((country, index) => (
                    <option key={index} value={country.text}>
                      {country.text}
                    </option>
                  ))}
                </Form.Select>
                {(errors.country && (Object.values(errors)?.length > 0 && !locationRef?.current?.value)) && (
                  <small role="alert" className="text-danger alert-msg">
                    Country is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicState">
                <Form.Label>State (Shipping)</Form.Label>
                <Form.Control autoComplete="new-text"
                  className={errors.state ? "border-danger" : ""}
                  type="text"
                  placeholder="State"
                  {...register("state",)}
                  disabled={watch('shipping_address1')}
                />
                {errors.state && (
                  <small role="alert" className="text-danger alert-msg">
                    Statw is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCity">
                <Form.Label>City (Shipping)</Form.Label>
                <Form.Control autoComplete="new-text"
                  className={errors.city ? "border-danger" : ""}
                  type="text"
                  placeholder="City"
                  {...register("city",)}
                  disabled={watch('shipping_address1')}
                />
                {errors.city && (
                  <small role="alert" className="text-danger alert-msg">
                    City is Required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicZipcode">
                <Form.Label>Zip / Postal Code (Shipping)*</Form.Label>
                <Form.Control
                  className={(errors.zipcode && (Object.values(errors)?.length > 0 && !locationRef?.current?.value)) ? "border-danger" : ""}
                  type="text" autoComplete="new-text"
                  placeholder="Zip / Postal Code (Shipping)"
                  {...register("zip_code", { required: true })}
                  disabled={!isZipCodeDisabled ? true : false}
                  onKeyPress={(event) => {
                    const inputValue = event.target.value + event.key;
                    const isValidZip = /^\d{5}(?:-\d{4})?$/.test(inputValue);
                    if (!isValidZip && event.key !== "-" && !/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                {(errors.zip_code || (Object.values(errors)?.length > 0 && !locationRef?.current?.value)) && (
                  <small role="alert" className="text-danger alert-msg">
                    Zip Code is Required
                  </small>
                )}
              </Form.Group>

              {finalPrice > 0 && (
                <Form.Group className="mb-3" controlId="formBasicCard">
                  <div className="form-group card-input position-relative" id="card-group">
                    <label htmlFor="Card">Card*</label>
                    <div id="card-element" className={`form-control ${renderCardErrorMessage() ? "req-box border-danger" : ""}`}>
                      <CheckoutForm required={true} />
                      {renderCardErrorMessage()}
                    </div>
                    <span className="w-100 text-end d-block small mt-3 fw-light">
                      Total due: ${finalPrice}
                    </span>
                  </div>
                </Form.Group>
              )}
              <button type="submit" className="submit-btn p-2">
                Pay
                {finalPrice >= 0 && (
                  <span id="amount" className="ms-1">
                    ${finalPrice}
                  </span>
                )}
              </button>
            </Form>
          </div>
        </div>
      </div>

    </>
  );
}
