import React, { useContext } from 'react'
import { GlobalContext } from '../../../App'
import { useLocation } from 'react-router-dom';

export default function Confirmation() {
    const { assetUrl } = useContext(GlobalContext);
    const location = useLocation();
    const { paymentData } = location.state;
    return (
        <div className="confirm-payment">
            <div class="cp_status-wrapper text-center p-4 page-container">
                <h2 class="cp_status-title text-black fw-bold mb-2 Inter_Med ">
                    <div class="flex justify-center mb-4 cp_status-icon">
                        <img src={assetUrl + 'check.svg'} alt="done" />
                    </div>
                    Payment confirmed!
                </h2>
                <div class="cp_status-content  mb-2">
                    <div class="cp_status-text">
                        <p>
                            We sincerely appreciate your recent purchase from Renew Beauty Med Spa. An email confirmation has been sent to
                            <br />
                            <span className='text-black'><strong>{paymentData?.email}</strong></span> .
                        </p>
                        <p>
                            Please anticipate upcoming email communication from our shipping department, which will contain comprehensive details regarding the delivery of your products.
                        </p>
                        <p>
                            Best,
                        </p>
                        <p className='mb-0'>
                            Renew Beauty Team
                        </p>
                    </div></div></div>
        </div >
    )
}
