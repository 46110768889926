import { useRef, useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../App";

const AddressSearch = ({ handleChange, location, setAddressData, className, name, locationRef, reset, onKeyDown }) => {
    const { assetUrl } = useContext(GlobalContext)
    const [showRemoveIcon, setShowRemoveIcon] = useState(false);
    const { id } = useParams();

    const options = {
        componentRestrictions: {},
        fields: ["geometry", "formatted_address", "address_components"],
        types: ["geocode", "establishment"]
    };

    useEffect(() => {
        if (id) {
            locationRef.current.value = location;
        }
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            inItRendered();
        }, 2000);
    }, []);
    useEffect(() => {
        if (locationRef.current) {
            setShowRemoveIcon(locationRef.current.value !== "");
        }
    }, [locationRef.current?.value]);

    const inItRendered = () => {
        let autocomplete = new window.google.maps.places.Autocomplete(locationRef?.current, options);

        window?.google?.maps?.event?.addListener(autocomplete, 'place_changed', function () {
            let place = autocomplete?.getPlace();
            let addressArr = place?.address_components || [];

            let upd = {
                location: place.formatted_address,
                lat: place?.geometry?.location?.lat(),
                long: place?.geometry?.location?.lng(),
                city: '',
                state: '',
                zip_code: '',
                country: '',
                street_number: ''
            };
            addressArr?.forEach((item) => {
                item.types.forEach((type) => {
                    if (type === "locality") {
                        upd.city = item?.long_name;
                    }
                    if (type === "administrative_area_level_1") {
                        upd.state = item?.long_name;
                    }
                    if (type === "postal_code") {
                        upd.zip_code = item?.long_name;
                    }
                    if (type === "country") {
                        upd.country = item?.long_name;
                    }
                    if (type === "route") {
                        upd.street_number = item?.long_name;
                    }
                });
            });

            handleChange(upd);
        });
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        handleChange({ location: newValue, zip_code: '' });
        setShowRemoveIcon(newValue !== "");
    };

    const clearLocation = () => {
        handleChange({
            location: "",
            lat: null,
            long: null,
            city: '',
            state: '',
            zip_code: '',
            country: ''
        });
        if (locationRef.current) {
            locationRef.current.value = "";
            setShowRemoveIcon(false);
        }
    };
    return (
        <div className="position-relative">
            <input
                ref={locationRef}
                placeholder="Enter Address"
                className={`cus_input input_control form-control w-100 location-in_icon ${className ? className : ""}`}
                type="text"
                name={name}
                onChange={handleInputChange}
                autocomplete="new-text"
                onKeyDown={onKeyDown}
            />
            {showRemoveIcon && <span className="removeIcon" onClick={clearLocation}><img src={assetUrl + "remove.png"} alt="Remove Location" /></span>}
        </div>
    );
};

export default AddressSearch;