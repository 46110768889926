import React, { useContext, useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GlobalContext } from "../App";

const stripePromise = loadStripe(
  "pk_test_wDlYbBsqPdfD9OfHS84AeZhS"
); //  API key for Stripe

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isCardComplete, setIsCardComplete] = useState(false);
  const { showSpinner, setShowSpinner, assetUrl } = useContext(GlobalContext)
  const handleSubmit = async (event) => {
    setShowSpinner(true)
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.createToken(elements.getElement(CardElement));
    if (result.error) {
      setShowSpinner(false)
      console.error(result.error.message);
    } else {
      setShowSpinner(false)
      setIsCardComplete(false)
      localStorage.setItem("Stripetoken", result.token.id);
      // You can send the token to your server to process the payment
    }
  };
  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  useEffect(() => {
    if (isCardComplete) {
      handleSubmit();
    }
  }, [isCardComplete]);
  return (
    <form>
      <CardElement onChange={handleCardChange} options={{ hidePostalCode: true }} />
    </form>
  );
};

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default Payment;
