import React, { useContext, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../App';
import { toast } from 'react-toastify';
import Spinner from '../../../widgets/Spinner';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { ClipLoader } from 'react-spinners';
function Home() {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setSubmitModal(false); reset() };
    const handleShow = () => setShow(true);
    const [submitModal, setSubmitModal] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, setValue, control, reset } = useForm();
    const { assetUrl } = useContext(GlobalContext)
    const [modalSpinner, setModalSpinner] = useState(false)
    // email detail api 
    const handleGetDetail = async (data) => {
        setModalSpinner(true)
        setSubmitModal(true)
        try {
            const detailData = {
                ...data,
            };
            const response = await fetch(
                ` ${process.env.REACT_APP_BASE_URL}/bundle-customers`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer "token"`,
                    },
                    body: JSON.stringify(detailData),
                }
            );
            if (response?.status === 200) {
                modalSpinner(false)
                const data = await response.json();
                toast.success(data?.message);
                setSubmitModal(true)
                reset()
                // navigate("/checkout")
            } else if (response?.status >= 400) {
                setModalSpinner(false)
                const data = await response.json();
                toast.error(data?.message);
            }
        } catch (error) {
            console.error(error);
            setModalSpinner(false)
        }
    };
    return (
        <>
            <main className='mainBanner'>
                <div className="containerCus">
                    <div className="mainBanner_wrrp">
                        <div className="d-md-flex align-items-center justify-content-between vh-100 py-5">
                            <div className="mainBanner_content">
                                <h3>DISCOVER</h3>
                                <h1>Nicole Ari Parker’s </h1>
                                <h3>EXCLUSIVE SKINCARE REGIMEN</h3>
                                <p>Dive into the secrets behind Nicole Ari Parker’s ever-glowing skin with the products and services she uses for her daily skin routine. Known for her radiant complexion on and off set, Nicole’s curated collection of skincare essentials, epitomizing opulence and sophistication, is now available to those, like herself, that appreciate the finer aspects of beauty.</p>
                                <button className='btnCus' onClick={handleShow}>Get Access</button>
                            </div>
                            <div className="mainBanner_img text-end">
                                <img src="../../assets/images/mainBannerImg.png" alt="Bannerimg" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* modal */}
            <Modal show={show} onHide={handleClose} className='modalhome' centered>
                <div className="bodyModal">
                    <h3 className='mb-3'>Receive Inside Access to Nicole's Beauty Details</h3>

                    {submitModal ?
                        <div className="submitted-modal text-center d-flex align-items-center justify-content-center">
                            {modalSpinner ?
                                <div className="modal-spinner d-flex align-items-center justify-content-center">
                                    <ClipLoader color="#fff" />
                                </div> : <img src={assetUrl + "check-fill.svg"} width={"60px"} alt="" />}

                        </div>
                        : <Form onSubmit={handleSubmit(handleGetDetail)}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/* <Form.Control className='inputFill' type="text" placeholder='FULL NAME' /> */}
                                <Form.Control
                                    className={`inputFill ${errors.name ? "border-danger" : ""}`}
                                    type="text"
                                    placeholder='FULL NAME'
                                    {...register("name", { required: true })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/* <Form.Control className='inputFill' type="email" placeholder='EMAIL ADDRESS' /> */}
                                <Form.Control
                                    className={`inputFill ${errors.email ? "border-danger" : ""}`}
                                    type="email"
                                    placeholder='EMAIL ADDRESS'
                                    {...register("email", { required: true })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/* <Form.Control
                                className={`inputFill ${errors.email ? "border-danger" : ""}`} type="tel" placeholder='PHONE NUMBER'
                                {...register("phone", { required: true, })}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                maxLength={20}
                                minLength={0}
                            /> */}
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <>
                                            <PhoneInput
                                                className={`rounded-2 ${errors.phone ? "border border-danger" : ""}`}
                                                specialLabel=""
                                                buttonClass="ph-drop"
                                                inputClass={`ps-5 inputFill form-control w-100 `}
                                                country={"us"} // set the defaultCountry to the selected country code
                                                value={field.value}
                                                onChange={field.onChange}
                                                containerClass={errors.phone ? "border-danger" : ""}
                                                inputExtraProps={{
                                                    maxLength: 3, // Assuming the country code length is 3
                                                    placeholder: 'Country Code' // Optional: You can customize the placeholder text
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Form.Group>
                            <button className='btnCus modalbtn'  >Preview Inside Access</button>
                        </Form>}

                </div>
            </Modal>

        </>
    )
}

export default Home