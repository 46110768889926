import { PuffLoader } from "react-spinners";


function Spinner() {
  return (
    <>
      <div className="sweet-loading spinner_dashboard">
        <PuffLoader size={50} thickness={100} speed={100} color="#bc9471" />

      </div>
    </>
  )
}

export default Spinner;
