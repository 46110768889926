
import './App.scss';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import { createContext, Suspense, useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import Home from './component/components/pages/Home';
import Confirmation from './component/components/pages/Confirmation';
import Checkout from './component/components/pages/Checkout';
import PrivateRoute from './widgets/PrivateRouts';
export const GlobalContext = createContext(null);

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/confirmation",
    element: <Confirmation />,
  },
]);
function App() {
  const assetUrl = './assets/images/'
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    localStorage?.setItem("auth", "false")
  }, []);

  return (
    <GlobalContext.Provider value={{ showSpinner, setShowSpinner, assetUrl }}>
      <Suspense fallback=""> {/* Use your FullScreenLoader component here */}
        <ToastContainer autoClose={3000} />
        <RouterProvider router={router} />
      </Suspense >
    </GlobalContext.Provider>
  );
}

export default App;
